import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Dialog, Loading } from 'sw-ui';

import {
  ACCOUNTTEXT,
  PERIODSUPD,
  SENDTYPE,
  ACCOUNT_DOC,
  UPD,
  SEND_TYPES,
  TAX_SHEMAS_SA,
} from '../../../../bi/constants/account';
import COMPONENTS from '../../../../bi/constants/components';

import HTTPCODES from '../../../../constants/http';

import { getDocumentsPackagesId } from '../../../../bi/utils/account';

import {
  getCurrentDatePlusYear,
  getStartDateOfLastMonth,
  getSmartwayBirthDate,
} from '../../../../utils/date';

import AjaxButton from '../../../../components/ajaxButton';
import RangeDatePicker from '../../../../components/RangeDatePicker';
import NoResult from '../../../../components/NoResult';
import { DocumentTemplates } from '../../../../v2/pages/Company/components/DocumentTemplates';

import ReportingSettingsForm from '../accounting/periodUpdForm';

import { EditCommentDialog } from './dialogs/commentDialog';
import { CreatePackageDialog } from './dialogs/createPackage';

import Table from './table';

import styles from './styles/index.module.css';

const SINCELASTMONTH = 'sinceLastMonth';
const ALLTIME = 'allTime';

const LABELS = {
  FIELD_NAMES: {
    COMMENT: 'Комментарий:',
    SCHEMA: 'Схема:',
    CONSTRUCTOR: 'Конструктор закрывающих:',
    TAX_SYSTEM: 'Система налогообложения:',
  },
  NOT_FORM_FLAG: 'Не формировать Акт сверки',
  CLOSE_TRIP: 'Закрывающие документы по совершенным поездкам',
  DISPLAY_DOC: 'Отображать документы',
  PREVIOUS_MONTH: 'с предыдущего месяца',
  ALL_TIME: 'за все время',
  COMMENT_MISSING: 'Отсутствует',
  TAX_SYSTEM_MISSING: 'Не выбрано',
  CLOSING_NO_FIO: 'Закрывающие документы без ФИО',
};

const DOCUMENT_SETTINGS = {
  CHANGIN_SEND_TYPE: 'ChangingSendingType',
  DEL_DOC_PACK: 'DeletingDocumentPackages',
  CREATING_DOC_PACK: 'CreatingDocumentPackages',
  EDO_SETTINGS: 'EdoSettings',
  ALL: 'All',
};

class Documents extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    toAnotherTab: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    accountService: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    onDocumentsConstructor: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      startDate,
      formedRevise,
      commentCompany,
      closingFio: {
        closingsWithoutFio,
        disable,
      },
      accounting: {
        details,
        schemas,
        periodUpd,
        periodEdo,
        taxShemasSA,
        edo,
        eventDate,
        userName,
        loadingAccountins,
      },
      documents: {
        packages,
        selectedPackages,
        pagination,
        requests,
      },
      packageDetails,
      packageChanges,
    } = props.companyService.get();

    this.state = {
      closingsWithoutFio,
      disabledFio: disable,
      commentCompany,
      taxShemasSA: taxShemasSA || 0,
      packageDetails,
      packageChanges,
      startDate,
      endDate: getCurrentDatePlusYear(),
      packages,
      requests,
      selectedPackages,
      pagination,
      schemas,
      details,
      periodUpd,
      periodEdo: periodEdo || 0,
      edo,
      eventDate,
      userName,
      formedRevise,
      showCommentDialog: false,
      showCreatePackageDialog: false,
      showUpdDialog: false,
      showTaxShemasDialog: false,
      fieldUpd: '',
      loading: true,
      loadingAccountins,

      waitingResponse: {
        packages: true,
        // ЭДО расшифровывается как Электронный Документооборот
        // EDM stands for Electronic Document Management
        EDMDownload: false,
        packagesDownload: false,
        vouchersDownload: false,
        packagesDelete: false,
      },
      docsChange: false,
      closingDocsChange: false,
      removeDocuments: false,
      isShowDiadocDialog: false,
      listDocumentSettings: [],
    };
  }

  componentDidMount() {
    const { companyService, featureFlagsService } = this.props;

    this.unsubscribe = companyService.subscribe(this.update);
    this.unsubFeatureFlags = featureFlagsService.subscribe(this.updateFeatureFlags);
    this.loadData();
    featureFlagsService.getAllFeatureFlags();
  }

  componentDidUpdate(_prevProps, prevState) {
    const { companyService: { getComment, getRulesFormingClosing }, companyId } = this.props;
    const { details: { Schema } } = this.state;

    if (prevState.details.Schema !== Schema) {
      getComment(companyId, Schema);
      getRulesFormingClosing(companyId, Schema);
    }
  }

  componentWillUnmount() {
    this.props.companyService.documents.reset();
    this.unsubscribe();

    if (this.unsubFeatureFlags) {
      this.unsubFeatureFlags();
    }
  }

  loadformedReviseFlag = () => this.props.companyService.documents.getFormedReviseFlag();

  loadData = async () => {
    const { companyService, accountService, accountId } = this.props;
    const { startDate, endDate } = this.state;

    try {
      await accountService.getSmartAgent(accountId);

      const res = await companyService.loadAccounting(accountId);
      await companyService.documents.loadDocuments(startDate, endDate);

      this.setState({ loading: false });

      return res;
    } catch (err) {
      return null;
    }
  };

  update = ({
    closingFio: {
      closingsWithoutFio,
      disable,
    },
    commentCompany,
    taxShemasSA,
    accounting,
    state,
    formedRevise,
    packageDetails,
    packageChanges,
    waitingResponse,
    documents: {
      packages,
      selectedPackages,
      pagination,
      requests,
    },
  }) =>
    this.setState({
      closingsWithoutFio,
      disabledFio: disable,
      commentCompany,
      taxShemasSA,
      ...state,
      ...accounting,
      packageDetails,
      formedRevise,
      packageChanges,
      ...waitingResponse,
      waitingResponse: {
        packages: false,
      },
      packages,
      selectedPackages,
      pagination,
      requests,
    });

  updateFeatureFlags = ({ DocsChange, ClosingDocsChange, RemoveDocuments, ClosingDocumentSettings }) => {
    this.setState({
      docsChange: DocsChange,
      closingDocsChange: ClosingDocsChange,
      removeDocuments: RemoveDocuments,
      listDocumentSettings: ClosingDocumentSettings,
    });
  };

  updateDocuments = () => {
    const { startDate, endDate } = this.state;
    const { companyService } = this.props;

    const updateDocuments = () => companyService.documents.loadDocuments(startDate, endDate);

    this.setState({ waitingResponse: { ...this.state.waitingResponse, packages: true } }, updateDocuments);
  };

  getSelectedPackagesIds(selectedPackages) {
    const selectedIds = [];

    Object.keys(selectedPackages).forEach((key) => {
      if (selectedPackages[key]) selectedIds.push(key);
    });

    return selectedIds;
  }

  toggleCreatePackagesDialog = () => this.setState({ showCreatePackageDialog: !this.state.showCreatePackageDialog });

  handleChangeDate = (field, value) => {
    if (!this.state[field].isSame(value)) {
      this.setState({
        [field]: value,
      }, this.updateDocuments);
    }
  };

  handleSetDate = (event, position) => {
    event.preventDefault();

    if (position === SINCELASTMONTH) {
      this.setState({
        startDate: getStartDateOfLastMonth(),
        endDate: getCurrentDatePlusYear(),
      }, this.updateDocuments);
    }
    if (position === ALLTIME) {
      this.setState({
        startDate: getSmartwayBirthDate(),
        endDate: getCurrentDatePlusYear(),
      }, this.updateDocuments);
    }
  };

  handleDownloadEDM = () => {
    const { selectedPackages } = this.state;
    const { companyService, toAnotherTab } = this.props;
    const packagesIds = this.getSelectedPackagesIds(selectedPackages);

    const download =
      () => companyService.documents.downloadEDM(packagesIds)
        .then(() => this.setState({
          waitingResponse: {
            ...this.state.waitingResponse,
            EDMDownload: false,
          },
        }))
        .catch((err) => {
          if (err.status === HTTPCODES.CONFLICT) {
            toAnotherTab('profile', 'showEditCompanyDialog=true');
          }
        });

    this.setState({ waitingResponse: { ...this.state.waitingResponse, EDMDownload: true } }, download);
  };

  handleDownloadPackages = () => {
    const { selectedPackages } = this.state;
    const { companyService } = this.props;
    const packagesIds = this.getSelectedPackagesIds(selectedPackages);

    const download =
      () => companyService.documents.downloadPackages(packagesIds)
        .finally(() => this.setState({
          waitingResponse: {
            ...this.state.waitingResponse,
            packagesDownload: false,
          },
        }));

    this.setState({ waitingResponse: { ...this.state.waitingResponse, packagesDownload: true } }, download);
  };

  handleDownloadVouchers = () => {
    const { selectedPackages } = this.state;
    const { companyService } = this.props;
    const packagesIds = this.getSelectedPackagesIds(selectedPackages);

    const download =
      () => companyService.documents.downloadVouchers(packagesIds)
        .finally(() => this.setState({
          waitingResponse: {
            ...this.state.waitingResponse,
            vouchersDownload: false,
          },
        }));

    this.setState({ waitingResponse: { ...this.state.waitingResponse, vouchersDownload: true } }, download);
  };

  handleSendDiadoc = async () => {
    const { packages, selectedPackages } = this.state;
    const { companyService: { sendDiadoc } } = this.props;
    const PackagesId = getDocumentsPackagesId(packages, selectedPackages);

    await sendDiadoc(PackagesId);
    this.setState({ isShowDiadocDialog: true });
  };

  handleDeletePackages = () => {
    const { selectedPackages } = this.state;
    const { companyService } = this.props;
    const packagesIds = this.getSelectedPackagesIds(selectedPackages);

    const deletePackages =
      () => companyService.documents.deletePackages(packagesIds)
        .finally(() => this.setState({
          waitingResponse: {
            ...this.state.waitingResponse,
            packagesDelete: false,
          },
        }, this.updateDocuments));

    this.setState({ waitingResponse: { ...this.state.waitingResponse, packagesDelete: true } }, deletePackages);
  };

  handleCreatePackages = monthAmt =>
    this.props.companyService.documents.createPackages(monthAmt)
      .then(this.toggleCreatePackagesDialog)
      .then(this.updateDocuments);

  handleShowDialog = field => this.setState({
    showUpdDialog: true,
    fieldUpd: field,
  });

  handleCloseDialog = () => this.setState({
    showUpdDialog: false,
    fieldUpd: '',
  });

  handleTaxShemasDialog = (value) => {
    this.setState({
      showTaxShemasDialog: value,
    });
  }

  handleShowDiadocDialog = isShowDiadocDialog => this.setState({ isShowDiadocDialog });

  handleOpenConstructor = () => {
    this.props.onDocumentsConstructor();
    window.scrollTo(0, 0);
  }

  handleFormedRevise = (value) => {
    const { companyId } = this.props;

    this.setState({ formedRevise: value });
    this.props.companyService.formedReviseFlag(companyId, value);
  }

  onChangeClosingsWithoutFIO = (value) => {
    const { details: { Schema } } = this.state;
    const { companyId } = this.props;

    this.setState({ closingsWithoutFio: value });
    this.props.companyService.setRulesFormingClosing(companyId, Schema, value);
  };

  handleSaveReporting = (period, schema, _periodEdo, sendingType) => {
    const { fieldUpd } = this.state;
    const { companyService } = this.props;
    const update = service => (
      service
        .then((res) => {
          if (res.Success) {
            return this.handleCloseDialog();
          }
          return Promise.reject(res.Message);
        })
    );

    if (fieldUpd === UPD.PERIOD) { return update(companyService.updateReportingPeriod(period)); }

    if (fieldUpd === UPD.SCHEMA) { return update(companyService.updateReportingSchema(schema)); }

    if (fieldUpd === UPD.SENDING_TYPE) { return update(companyService.updateReportingSendingType(sendingType)); }

    return null;
  };

  handleSavePeriodEdo = (periodEdo, edo) => {
    this.props.companyService.updatePeriodEdo(periodEdo, edo).then(() => this.handleCloseDialog());
  };

  handleSaveTaxShemasSA = (taxShemas) => {
    this.props.companyService.setTaxShemasSA(taxShemas).then(() => this.handleCloseDialog());
  }

  checkingDocumentSettings = (value) => {
    const { listDocumentSettings } = this.state;

    if (!listDocumentSettings) return false;

    return listDocumentSettings.some(item => item === DOCUMENT_SETTINGS.ALL || item === value);
  }

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading size={ COMPONENTS.LOADING.SIZE.LARGE } />
    </div>
  );

  renderClosingFIO = () => {
    const { closingsWithoutFio, disabledFio } = this.state;

    if (disabledFio) {
      return null;
    }

    return (
      <div
        className={ styles.wrapper_checkbox }
      >
        <Checkbox
          value={ closingsWithoutFio }
          disabled={ disabledFio }
          onChange={ value => this.onChangeClosingsWithoutFIO(value) }
        >
          { LABELS.CLOSING_NO_FIO }
        </Checkbox>
      </div>
    );
  };

  renderHeader = () => {
    const { formedRevise } = this.state;

    const flagformedRevise = this.props.featureFlagsService.get().CompanyReviseChange;

    return (
      <div className={ styles.header }>
        <p>
          { LABELS.CLOSE_TRIP }
        </p>
        <div>
          <Checkbox
            value={ !formedRevise }
            onChange={ value => this.handleFormedRevise(!value) }
            disabled={ !flagformedRevise }
          >
            { LABELS.NOT_FORM_FLAG }
          </Checkbox>
          { this.renderClosingFIO() }
        </div>
      </div>
    );
  }

  renderDateRange = (startDate, endDate) => (
    <div className={ styles['date-range'] }>
      <span className={ styles['date-text'] }>{LABELS.DISPLAY_DOC}</span>
      <RangeDatePicker
        startDate={ startDate }
        endDate={ endDate }
        onChange={ this.handleChangeDate }
        placeholderNameFrom={ ACCOUNTTEXT.FROM }
        placeholderNameTo={ ACCOUNTTEXT.TO }
      />
      <div className={ styles.position }>
        <span>(&nbsp;</span>
        <a
          className={ styles.link }
          onClick={ event => this.handleSetDate(event, SINCELASTMONTH) }
        >
          {LABELS.PREVIOUS_MONTH}
        </a>
        <span>,&emsp;</span>
        <a
          className={ styles.link }
          onClick={ event => this.handleSetDate(event, ALLTIME) }
        >
          {LABELS.ALL_TIME}
        </a>
        <span>&nbsp;)</span>
      </div>
    </div>
  );

  renderShowDeleteDoc = (isDisabled, packagesDelete) => {
    const { docsChange } = this.state;

    return (docsChange && this.checkingDocumentSettings(DOCUMENT_SETTINGS.DEL_DOC_PACK)) ? (
      <div className={ styles.action }>
        <AjaxButton
          label={ ACCOUNT_DOC.DELETE_PACKAGE }
          loading={ packagesDelete }
          disabled={ isDisabled }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.handleDeletePackages }
        />
      </div>
    ) : null;
  };

  renderDiadocButton = (isDisabled) => {
    const { details: { SendingType }, requests: { requestDiadoc: { isRequest } } } = this.state;

    return SendingType.includes(SEND_TYPES.BY_DIADOC) && (
      <div className={ styles.action }>
        <AjaxButton
          label={ ACCOUNT_DOC.SEND_DIADOC }
          loading={ isRequest }
          disabled={ isDisabled || isRequest }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.handleSendDiadoc }
        />
      </div>
    );
  };

  renderDiadocModalResult = () => {
    const { requests: { requestDiadoc: { msg } }, isShowDiadocDialog } = this.state;

    if (!isShowDiadocDialog) {
      return null;
    }

    return (
      <NoResult
        message={ msg }
        onClose={ () => this.handleShowDiadocDialog(false) }
      />
    );
  }

  toggleModalComment = (value = false) => this.setState(({ showCommentDialog: value }));

  renderComment = () => {
    const { commentCompany } = this.state;

    const comment = (commentCompany && commentCompany.text)
      ? commentCompany.text
      : LABELS.COMMENT_MISSING;

    return (
      <div className={ styles['wrapper-comment'] }>
        { LABELS.FIELD_NAMES.COMMENT }
        <span className={ styles.comment }>
          { comment }
        </span>
      </div>
    );
  };

  renderShemaNalogSA = () => {
    const { isSmartAgent } = this.props.accountService.get();
    const { accounting: { taxShemasSA } } = this.props.companyService.get();

    if (!isSmartAgent) {
      return null;
    }

    const tax = taxShemasSA || 0;

    return (
      <div className={ styles.wrapper_nalog }>
        { LABELS.FIELD_NAMES.TAX_SYSTEM }
        <span className={ styles.comment }>
          { TAX_SHEMAS_SA[tax].label }
        </span>
        <div className={ styles.button }>
          { this.renderShowChangeButton(UPD.TAX_SHEMAS_SA) }
        </div>
      </div>
    );
  };

  renderCommentDiaolog = (details, schemas) => {
    const { companyService, companyId } = this.props;
    const { showCommentDialog, commentCompany } = this.state;

    if (!showCommentDialog) {
      return null;
    }

    const comment = commentCompany ?
      commentCompany.text
      : '';

    return (
      <EditCommentDialog
        onClose={ this.toggleModalComment }
        companyService={ companyService }
        companyId={ companyId }
        getSchemas={ schemas }
        schema={ details.Schema }
        comment={ comment }
      />
    );
  };

  renderBtnCreatePac = () => {
    if (!this.checkingDocumentSettings(DOCUMENT_SETTINGS.CREATING_DOC_PACK)) {
      return null;
    }

    return (
      <div>
        <div className={ styles.action }>
          <Button
            label={ ACCOUNT_DOC.CREATE_PACKAGES }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ this.toggleCreatePackagesDialog }
          />
        </div>
      </div>
    );
  };

  renderActions = (isDisabled, { EDMDownload, packagesDownload, vouchersDownload, packagesDelete }) => (
    <div className={ styles.actions }>
      <div>
        <div className={ styles.action }>
          <AjaxButton
            label={ ACCOUNT_DOC.DOWNLOAD_ADO }
            loading={ EDMDownload }
            disabled={ isDisabled }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ this.handleDownloadEDM }
          />
        </div>
        <div className={ styles.action }>
          <AjaxButton
            label={ ACCOUNT_DOC.DOWNLOAD_CLOTHED }
            loading={ packagesDownload }
            disabled={ isDisabled }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ this.handleDownloadPackages }
          />
        </div>
        <div className={ styles.action }>
          <AjaxButton
            label={ ACCOUNT_DOC.DOWNLOAD_VOUCHERS }
            loading={ vouchersDownload }
            disabled={ isDisabled }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ this.handleDownloadVouchers }
          />
        </div>
        { this.renderDiadocButton(isDisabled)}
        { this.renderShowDeleteDoc(isDisabled, packagesDelete) }
      </div>
      { this.renderBtnCreatePac() }
    </div>
    )

  renderSchemasDialog = () => {
    const {
      showUpdDialog,
      details: {
        Schema,
        SendingType,
        INN,
        Index,
        RegionCode,
      },
      periodUpd,
      periodEdo,
      edo,
      schemas,
      fieldUpd,
      eventDate,
      userName,
      taxShemasSA,
    } = this.state;

    if (!showUpdDialog) {
      return null;
    }

    const isSavingCondition = !(INN && Index && RegionCode);

    return (
      <Dialog onClick={ this.handleCloseDialog } width={ COMPONENTS.DIALOG.WIDTH.MIDDLE }>
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          <ReportingSettingsForm
            currentPeriod={ periodUpd }
            currentPeriodEdo={ periodEdo }
            edo={ edo }
            currentSchemas={ Schema }
            userName={ userName }
            dateCorrection={ eventDate }
            currentShemasSA={ taxShemasSA }
            currentSendingType={ SendingType }
            schemas={ schemas }
            visible={ fieldUpd }
            onSave={ this.handleSaveReporting }
            onSaveEdo={ this.handleSavePeriodEdo }
            isSavingCondition={ isSavingCondition }
            onSaveShemas={ this.handleSaveTaxShemasSA }
          />
        </div>
      </Dialog>
    );
  };

  renderEditCommentButton = () => this.state.closingDocsChange && (
    <button
      className={ styles['button-correction'] }
      onClick={ () => this.toggleModalComment(true) }
    >
      <i className='material-icons'>mode_edit</i>
    </button>
  );

  renderShowChangeButton = (period) => {
    const label = period === UPD.PERIOD_EDO
      ? ACCOUNTTEXT.CLOSING_SETTINGS
      : ACCOUNTTEXT.EDIT;

    return this.state.closingDocsChange && (
      <Button
        label={ label }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ () => this.handleShowDialog(period) }
      />
    );
  }

  renderDocumentsConstructor = () => {
    const { featureFlagsService } = this.props;

    if (!featureFlagsService.getClosingDocumentsConstructor()) {
      return null;
    }

    return (
      <div className={ `${styles.row} ${styles['button-change']}` }>
        <div> {LABELS.FIELD_NAMES.CONSTRUCTOR} </div>
        <Button
          label={ ACCOUNTTEXT.EDIT }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.handleOpenConstructor }
        />
      </div>
    );
  };

  sendingTypeName = () => {
    const { details } = this.state;
    if (!details) {
      return null;
    }

    const keys = details.SendingType.split(', ');
    return SENDTYPE.reduce((acc, { value, label }) => (keys.includes(value) ? [...acc, label] : acc), [])
    .join(', ');
  }

  renderTable = ({
    loading,
    companyService,
    packages,
    packageChanges,
    packageDetails,
    selectedPackages,
    pagination,
    toAnotherTab,
    docsChange,
    removeDocuments,
    companyId,
    loadDocuments,
  }) => (
    loading ? this.renderLoading() : (
      <Table
        companyService={ companyService }
        toAnotherTab={ toAnotherTab }
        packages={ packages }
        packageDetails={ packageDetails }
        packageChanges={ packageChanges }
        selectedPackages={ selectedPackages }
        pagination={ pagination }
        docsChange={ docsChange }
        removeDocuments={ removeDocuments }
        companyId={ companyId }
        loadDocuments={ loadDocuments }
      />
    )
  );

  render() {
    const {
      companyService,
      toAnotherTab,
      companyId,
    } = this.props;
    const {
      startDate,
      endDate,
      packages,
      packageDetails,
      packageChanges,
      selectedPackages,
      pagination,
      showCreatePackageDialog,
      waitingResponse,
      docsChange,
      loading,
      details,
      schemas,
      periodUpd,
      removeDocuments,
      loadingAccountins,
    } = this.state;

    const isActionsDisabled = !this.getSelectedPackagesIds(selectedPackages).length;

    const accessTypeSendingFlag = this.checkingDocumentSettings(DOCUMENT_SETTINGS.CHANGIN_SEND_TYPE) ? this.renderShowChangeButton(UPD.SENDING_TYPE) : null;
    const accessEDOSettingFlag = this.checkingDocumentSettings(DOCUMENT_SETTINGS.EDO_SETTINGS) ? this.renderShowChangeButton(UPD.PERIOD_EDO) : null;

    const createDocumentsPackageDialog = showCreatePackageDialog ? (
      <CreatePackageDialog
        companyService={ companyService }
        onCreate={ this.handleCreatePackages }
        onClose={ this.toggleCreatePackagesDialog }
      />
    ) : null;

    return (
      loading || loadingAccountins ? this.renderLoading() : (
        <div className={ styles.wrap }>
          { this.renderHeader() }
          { this.renderDateRange(startDate, endDate) }
          <div className={ `${styles.row} ${styles['button-change']}` }>
            <div> {LABELS.FIELD_NAMES.SCHEMA} { schemas[details.Schema] }</div>
            { this.renderShowChangeButton(UPD.SCHEMA) }
          </div>
          { this.renderDocumentsConstructor() }
          <div className={ `${styles.row} ${styles['button-change']}` }>
            { this.renderComment() }
            { this.renderEditCommentButton() }
          </div>
          <div className={ `${styles.row} ${styles['button-change']}` }>
            <div>Период закрывающих документов: { PERIODSUPD[periodUpd].label }</div>
            { this.renderShowChangeButton(UPD.PERIOD) }
          </div>
          { this.renderShemaNalogSA() }
          <div className={ `${styles.row} ${styles['button-change']}` }>
            <div>Типы отправки: { this.sendingTypeName() }</div>
            { accessTypeSendingFlag }
          </div>
          <div className={ `${styles.row} ${styles['button-change-edo']}` }>
            { accessEDOSettingFlag }
          </div>
          { this.renderSchemasDialog() }
          <DocumentTemplates />
          { this.renderActions(isActionsDisabled, waitingResponse) }
          { this.renderTable({
            loading: waitingResponse.packages,
            companyService,
            packages,
            packageDetails,
            packageChanges,
            selectedPackages,
            pagination,
            toAnotherTab,
            docsChange,
            removeDocuments,
            companyId,
            loadDocuments: this.loadData,
          }) }
          { createDocumentsPackageDialog }
          { this.renderDiadocModalResult() }
          { this.renderCommentDiaolog(details, schemas) }
        </div>
      )
    );
  }
}

export default Documents;
